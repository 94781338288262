
import styled from 'styled-components'
export const Wrapper = styled.div`
  @keyframes moveUpAndDown {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(0.5rem); /* Adjust this value to change the height of the jump */
      }
    }

    .animate-span {
      animation: moveUpAndDown 0.8s linear infinite;
    }
    .politOne{
        animation-delay: 0s;
    }
    .politTwo{
        animation-delay: 0.2s;
    }
    .politThree{
        animation-delay: 0.4s;
    }
`


