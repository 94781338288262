import useApi from '../useApi'

const useRequest = () => {
  const Request = useApi()

  const loadConversation = async () => {
    return await Request.get('load')
  }
  const getConversationsOutlines = async () => {
    return await Request.get('getConversationsOutlines')
  }

  const postMessage = async (data) => {
    return await Request.post('post', data)
  }
  const clearSession = async () => {
    return await Request.post('clearSession')
  }
  const resetConversation = async (data) => {
    return await Request.post('resetConversation', data)
  }
  const setAdministrationUsers = async (data) => {
    return await Request.post('setAdministrationUsers', data)
  }
  const moveToNextConversation = async (data) => {
    return await Request.post('moveToNextConversation', data)
  }
  const regenerateAnswer = async (data) => {
    return await Request.post('regenerateAnswer', data)
  }
  const rewriteUserMessage = async (data) => {
    return await Request.post('rewriteUserMessage', data)
  }
  const setChatMessageFeedback = async (data) => {
    return await Request.post('setChatMessageFeedback', data)
  }
  const executeOutputResult = async () => {
    return await Request.post('executeOutputResult')
  }

  return {
    loadConversation,
    getConversationsOutlines,
    postMessage,
    clearSession,
    resetConversation,
    setAdministrationUsers,
    moveToNextConversation,
    regenerateAnswer,
    setChatMessageFeedback,
    rewriteUserMessage,
    executeOutputResult,
  }
}

export default useRequest
