'use client'
import 'react-toastify/dist/ReactToastify.css'
import React from 'react'
import { Provider } from 'react-redux'
import store from '../../../store/store'
import AppWrapper from '../AppWrapper'
import { ToastContainer } from 'react-toastify'

export default function ReduxProvider({ children }) {
  return (
    <Provider store={store}>
      <AppWrapper>
        {children}
        <ToastContainer />
      </AppWrapper>
    </Provider>
  )
}
