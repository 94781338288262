import MagicButton from '../../MagicButton/MagicButton'
import { FormattedMessage } from 'react-intl'

export default function NoContent() {
  return (
    <div className="noMessage h-full flex flex-col items-center justify-center flex-grow bg-no-repeat bg-center  ">
      <MagicButton className="mt-[5.3rem] mb-4  p-3 " />
      <div className="title">
        <h1 className="text-lg font-semibold  text-themeGray-900">
          <FormattedMessage id="Get-started-with-OSOS-AI" />
        </h1>
      </div>
      <div className="subtitle text-center">
        <h2 className="text-sm text-themeGray-600">
          <FormattedMessage id="Create-business-apps-with-AI" />
          <br /> <FormattedMessage id="From-Prompt-to-Reality!" />
        </h2>
      </div>
    </div>
  )
}
