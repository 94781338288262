import React from 'react'
import { PiArrowsClockwiseFill } from 'react-icons/pi'
import { useSelector, useDispatch } from 'react-redux'
import useRequest from '../../../../../axios/apis/useRequest'
import { reloadFun, setAllMessage } from '../../../../../store/slices/chat'
import { FormattedMessage } from 'react-intl'

export default function SingleSeparator({
  title,
  conversationKey,
  lineColor = 'bg-themeGray-200',
}) {
  const { resetConversation } = useRequest()
  const dispatch = useDispatch()
  const preloader = useSelector((state) => state.main.preloader)

  return (
    <div className="flex justify-between items-center  ">
      <div className="title text-themeGray-600 flex text-sm font-semibold rtl:ml-2 relative  min-w-max">
        {title}
      </div>
      <div
        className={`line top-[50%] ${lineColor} h-[1px] content-[''] w-full mx-2`}
      ></div>
      <button
        disabled={preloader}
        onClick={async () => {
          await resetConversation({ conversationKey })
          dispatch(setAllMessage([]))
          dispatch(reloadFun())
        }}
        className="reset flex items-center border border-themeGray-300 py-1 px-2 rounded-md text-themeGray-700 font-semibold bg-themeWhite-white"
      >
        <span className="icon mr-2 rtl:mr-0 rtl:ml-2 font-semibold">
          <PiArrowsClockwiseFill />
        </span>
        <span className="text-xs min-w-max">
          <FormattedMessage id="Reset" />
        </span>
      </button>
    </div>
  )
}
