import React from 'react'
import { LuSend } from 'react-icons/lu'
import { useSelector, useDispatch } from 'react-redux'
import { setMessage, addMessage } from '../../../store/slices/chat'
import useRequest from '../../../axios/apis/useRequest'
import useChat from '../../../hooks/useChat'
import { useIntl } from 'react-intl'

export default function Footer() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { postMessage } = useRequest()
  const currentMessage = useSelector((state) => state.chat.currentMessage)
  const conversationKey = useSelector((state) => state.chat.conversationKey)
  const preloader = useSelector((state) => state.main.preloader)
  const { messageResponse } = useChat()

  return (
    <form className="justify-center w-full p-3 pb-4 bg-themeGray-50 flex items-strech items-stretch border-t">
      <textarea 
        rows='1'//only 1 row to match the design and keep it similar to the textbox
        className="flex-grow border border-[#B2DDFF] mr-2 rtl:mr-0 rtl:ml-2 rounded-md pl-3 rtl:pl-0 rtl:pr-3 focus-visible:outline-0 bg-themeWhite-white"
        placeholder={intl.formatMessage({
          id: 'Type-your-message-here',
        })}
        onChange={(e) => dispatch(setMessage(e.target.value))}
        onKeyDown={(e) => {//to support send message when Enter pressed. because textarea default behavior is insert new-line when Enter is pressed 
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            document.getElementById('sendButton').click();
          }
        }}
        style={{ paddingTop: "0.4rem"}}//to make sure place holder is in the center
        value={currentMessage}
      />

      <button
        disabled={preloader}
        className="bg-primary-600 flex text-themeWhite-white text-lg rounded-md items-center justify-center w-10 h-10 rtl:scale-x-[-1]"
        type="submit"
        id="sendButton"
        onClick={async (e) => {
          e.preventDefault()
          if (currentMessage.length > 0) {
            dispatch(setMessage(''))
            dispatch(
              addMessage([
                {
                  message: currentMessage,
                  owner: 'you',
                  time: Date.now(),
                  type: 'message',
                },
              ])
            )

            const sendMessage = await postMessage({
              conversationKey,
              message: currentMessage,
            })
            messageResponse(sendMessage?.data)
          }
        }}
      >
        <LuSend />
      </button>
    </form>
  )
}
