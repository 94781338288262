import { configureStore } from '@reduxjs/toolkit'
import mainReducer from './slices/main'
import chatReducer from './slices/chat'
 
export default configureStore({
  reducer: {
    main: mainReducer,
    chat: chatReducer,
   },
})
