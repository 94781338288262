import React, { useState } from 'react'
import { AiOutlineDislike, AiOutlineLike } from 'react-icons/ai'
import CheckBox from '../../../CheckBox/CheckBox'
import useRequest from '../../../../../axios/apis/useRequest'
import { FormattedMessage, useIntl } from 'react-intl'

export default function FeedBack({
  type,
  setShow,
  messageId,
  messageConversationKey,
}) {
  const { setChatMessageFeedback } = useRequest()
  const intl = useIntl()

  const checkboxes = {
    confusingResponse: {
      label: <FormattedMessage id="Confusing-Response" />,
      name: 'confusingResponse',
      status: false,
    },

    irrelevantInformation: {
      label: <FormattedMessage id="Irrelevant-Information" />,
      name: 'irrelevantInformation',
      status: false,
    },
    missedContext: {
      label: <FormattedMessage id="Missed-Context" />,
      name: 'missedContext',
      status: false,
    },
    lackOfCreativity: {
      label: <FormattedMessage id="Lack-Of-Creativity" />,
      name: 'lackOfCreativity',
      status: false,
    },
  }

  const [textareaValue, setTextareaValue] = useState('')
  const data = {
    like: {
      placeholder: intl.formatMessage({
        id: 'What-do-you-like-about-this-response?',
      }),
    },
    dislike: {
      placeholder: intl.formatMessage({
        id: 'What-was-the-issue-with-the-response?-How-could-it-be-improved?',
      }),
    },
  }

  const submit = () => {
    let result
    // if (type == 'like') {
    //   result = textareaValue
    // } else {
    result = Object.assign(
      {},
      ...Object.entries(checkboxes).map(([key, value]) => ({
        [key]: value.status,
      }))
    )

    result.text = textareaValue
    result.chatMessageId = messageId
    result.type = 0
    result.conversationKey = messageConversationKey

    // }

    setChatMessageFeedback(result)
    setShow(false)
  }
  return (
    <div className="flex z-50  justify-center items-center fixed top-0 left-0 w-screen h-screen backdrop-blur-[8px] ">
      <span
        className="bg-themeBlack-black opacity-60 w-full h-full absolute"
        onClick={() => setShow(false)}
      ></span>
      <div className="z-10 max-w-[90%] min-w-[19rem]">
        <div className="bg-themeWhite-white rounded-md p-6">
          <div className="header flex items-start">
            <div
              className={`icon mr-4 scale-x-[-1] rtl:scale-x-[1] rtl:mr-0 rtl:ml-4 p-3 rounded-full text-2xl ${
                type == 'dislike'
                  ? 'bg-error-100  text-error-700'
                  : 'bg-themeGreen-100  text-themeGreen-700'
              }`}
            >
              {type == 'dislike' ? <AiOutlineDislike /> : <AiOutlineLike />}
            </div>
            <div className="text">
              <div className="header-title text-themeGray-900 text-lg font-semibold">
                <FormattedMessage id="Provide-additional-feedback" />
              </div>
              <div className="subTitle text-sm text-themeGray-600">
                <FormattedMessage id="Share-with-us-how-can-we-improve" />
              </div>
            </div>
          </div>
          <div className="body border-themeGray-300 border rounded-md p-4 text-themeGray-600 my-6">
            <textarea
              className="w-full  focus:outline-none h-[9rem] resize-none"
              placeholder={data[type].placeholder}
              value={textareaValue}
              onChange={(e) => setTextareaValue(e.target.value)}
            />
          </div>
          {type == 'dislike' && (
            <div className="checkboxContainer">
              <div className="flex items-center justify-between mb-3">
                <div className="checkbox">
                  {Object.values(checkboxes).map((checkbox, index) => (
                    <CheckBox
                      label={checkbox.label}
                      key={index}
                      name={checkbox.name}
                      action={() => {
                        checkboxes[checkbox.name].status =
                          !checkboxes[checkbox.name].status
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="actions">
            <button
              type="button"
              className=" w-full text-center rounded-md bg-primary-600 text-themeWhite-white p-2.5 my-1.5 font-semibold"
              onClick={submit}
            >
              <FormattedMessage id="Submit-Feedback" />
            </button>
            <button
              type="button"
              className="w-full text-center rounded-md border-themeGray-300 border text-themeGray-700 p-2.5 my-1.5 font-semibold"
              onClick={() => setShow(false)}
            >
              <FormattedMessage id="Cancel" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
