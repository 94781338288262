import AppWrapper from './components/global/reduxProvider/ReduxProvider'
import Footer from './components/shared/Footer/Footer'
import Main from './components/shared/Main/Main'
import Header from './components/shared/Header/Header'

function App() {
  return (
    <div className="App">
      <AppWrapper>
        <div className="bodyContainer flex flex-col min-h-screen ">
          <Header />
          <Main />
          <Footer />
        </div>
      </AppWrapper>
    </div>
  )
}

export default App
