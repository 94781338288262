import styled from 'styled-components'
export const Wrapper = styled.div`
  &.magic {
    position: relative;
    overflow: hidden;
    border-color: var(--primary-300);
    z-index: 10;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background: linear-gradient(
        to bottom,
        var(--primary-300),
        var(--primary-100)
      );
    }
    &:after {
      content: '';
      position: absolute;
      top: 0.13rem;
      left: 0.13rem;
      width: calc(100% - 0.25rem);
      height: calc(100% - 0.25rem);
      border-radius: 50%;

      z-index: -1;

      background: ${props => props.bg};
    }
  }
`
