import React from 'react'
import headerCircle from '../../../assets/images/headerCircle.svg'
import { Wrapper } from './Header.styled'
import MagicButton from '../MagicButton/MagicButton'
import { reloadFun, setAllMessage } from '../../../store/slices/chat'
import useRequest from '../../../axios/apis/useRequest'
import { BiMessageAdd } from 'react-icons/bi'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'

export default function Header() {
  const preloader = useSelector((state) => state.main.preloader)

  const direction = useSelector((state) => state.main.direction)
  const { clearSession } = useRequest()
  const dispatch = useDispatch()

  return (
    <Wrapper className="flex relative" direction={direction}>
      <div className="noContent">
        <img src={headerCircle} alt="" className="headerBg  rtl:scale-x-[-1]" />
        <div className="absolute top-0 left-0 w-full h-full flex  items-center  px-3.5">
          <MagicButton className="bg-themeWhite-white relative  p-3 " />
          <div className="content  text-themeWhite-white p-3.5">
            <div className="title">
              <h1 className="text-lg font-semibold">
                <FormattedMessage id="OSOS-AI" />
              </h1>
            </div>
            <div className="subtitle ">
              <h2 className="text-sm">
                <FormattedMessage id="Your-AI-assistant" />
              </h2>
            </div>
          </div>
        </div>
        <span className="fa-solid fa-ellipsis-vertical text-xl absolute right-2 top-2 rtl:right-auto rtl:left-2 text-themeWhite-white p-3 cursor-pointer">
          <button
            disabled={preloader}
            Chat
            type="button"
            className="flex items-center justify-center border border-primary-200 bg-themeWhite-white text-primary-600 rounded-md text-sm px-2 py-1 font-semibold hover:bg-primary-500 hover:text-primary-100"
            onClick={async () => {
              localStorage.removeItem('endpoint')
              await clearSession()
              dispatch(setAllMessage([]))
              dispatch(reloadFun())
            }}
          >
            <span className="text">
              <FormattedMessage id="New-Chat" />
            </span>
            <span className="icon pl-2 rtl:pl-0 rtl:pr-2 text-xl mb-[-0.1rem]">
              <BiMessageAdd />
            </span>
          </button>
        </span>
      </div>
    </Wrapper>
  )
}
