import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Wrapper } from './SelectUser.style'
import axios from 'axios'
import useRequest from '../../../../../axios/apis/useRequest'
import { useSelector, useDispatch } from 'react-redux'
import { addMessage } from '../../../../../store/slices/chat'
import { FormattedMessage, useIntl } from 'react-intl'

// You may need to import the user icon image or use an icon library

const SelectUser = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { setAdministrationUsers } = useRequest()
  const conversationKey = useSelector((state) => state.chat.conversationKey)
  const [options, setoptions] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = 'Bearer ' + localStorage.getItem('token')
        const response = await axios.get(process.env.REACT_APP_SELECT_USERS, {
          headers: {
            Authorization: token,
          },
        })
        const users = response.data.result.map((user) => {
          return {
            value: user.id,
            label: user.value.account_information.username,
          }
        })

        setoptions(users)
      } catch (error) {
        console.log(error)
        if (error.message === 'Network Error') {
          window.parent.postMessage('token', document.referrer)
        }
      }
    }
    fetchData()
  }, [localStorage.getItem('token')])

  const sendUsers = async () => {
    const users = selectedUsers.map((user) => {
      return {
        value: user.value,
        text: user.label,
      }
    })

    if (users.length == 0) return

    const postData = {
      conversationKey,
      items: users,
    }

    let usersMessage = ''
    selectedUsers.map((user) => {
      usersMessage += usersMessage.length > 0 ? ', ' + user.label : user.label
    })

    dispatch(
      addMessage([
        {
          type: 'message',
          conversationKey,
          owner: 'you',
          status: 'response',
          message: usersMessage,
          tag: 0,
          time: new Date(),
        },
      ])
    )

    const replay = await setAdministrationUsers(postData)
    if (replay?.data?.data) {
      const newMessages = replay.data.data.map((message) => {
        return {
          type: 'message',
          conversationKey,
          owner: 'OSOS-AI',
          id: message.id,
          status: 'response',
          message: message.content.replace(/(\r\n|\n|\r)/g, '<br>'),
          tag: message.tag,
          time: message.createTime,
        }
      })

      dispatch(addMessage(newMessages))
    }
  }
  return (
    <Wrapper className="userSelector mt-8 ml-auto rtl:ml-0 rtl:mr-auto max-w-[85%]">
      <div className="flex rounded-md  border border-themeGray-300 ">
        <div className="w-full">
          <Select
            isMulti
            options={options}
            placeholder={intl.formatMessage({
              id: 'Select-Users',
            })}
            onChange={(selectedOption) => setSelectedUsers(selectedOption)}
          />
        </div>
        <button
          className="send bg-primary-600 text-themeWhite-white flex items-center px-2  rounded-tr-md rounded-br-md  rtl:rounded-tl-md rtl:rounded-bl-md rtl:rounded-br-none rtl:rounded-tr-none"
          onClick={sendUsers}
        >
          <FormattedMessage id="Send" />
        </button>
      </div>
    </Wrapper>
  )
}

export default SelectUser
