import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Wrapper } from './CheckBox.styled'

export default function CheckBox({ label, status = false, action, name }) {
  const borderRound = useSelector((state) => state.main.borderRound)
  const [checkStatus, setCheckStatus] = useState(status)
  return (
    <Wrapper
      borderRound={borderRound}
      isEnglish={window.location.href.endsWith('/ar')}
    >
      {label && (
        <div className=" text-themeGray-700 text-sm ml-2 rtl:ml-0 rtl:mr-2 font-semibold">
          {label}
        </div>
      )}
      <input
        type="checkbox"
        name={name}
        checked={checkStatus}
        onChange={() => {
          setCheckStatus(!checkStatus)
          action && action()
        }}
      />
      <span className="checkmark "></span>
    </Wrapper>
  )
}
