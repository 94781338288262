import React from 'react'
import { Wrapper } from './TextLoading.styled'

export default function TextLoading() {
  return (
      <Wrapper>
          <span className='politOne animate-span w-[0.3rem] h-[0.3rem] bg-themeGray-600 rounded-full m-0.5 inline-block relative top-[-0.3rem]'></span>
          <span className='politTwo animate-span w-[0.3rem] h-[0.3rem] bg-themeGray-300 rounded-full m-0.5 inline-block relative top-[-0.3rem]'></span>
          <span className='politThree animate-span w-[0.3rem] h-[0.3rem] bg-themeGray-600 rounded-full m-0.5 inline-block relative top-[-0.3rem]'></span>
    </Wrapper>
  )
}
