import useRequest from '../axios/apis/useRequest'
import { addMessage, reloadFun, setTextLoading } from '../store/slices/chat'
import { useSelector, useDispatch } from 'react-redux'
import { setConversationKey } from '../store/slices/chat'

let requestNumberCall = 0
export default function useChat() {
  const actionsCheck = (message, messageObject) => {
    if (message.messageActions && message.tag == 100) {
      const messageActions = []
      message.messageActions.map((action) => {
        if (action.type == 1) {
          messageActions.push({
            url: action.arguments.url,
            urlTitle: action.arguments.urlTitle,
          })
        } else if (action.type == 2) {
          localStorage.setItem('endpoint', action.arguments.endpoint)
          dispatch(reloadFun())
        }
      })
      if (messageActions.length > 0) {
        messageObject.messageActions = messageActions
      }
    }

    return messageObject
  }
  const dispatch = useDispatch()
  const conversationKey = useSelector((state) => state.chat.conversationKey)
  const {
    moveToNextConversation,
    getConversationsOutlines,
    executeOutputResult,
  } = useRequest()

  const conversationKeyStatus = {
    0: 'inFuture',
    1: 'done',
    2: 'inprogress',
    3: 'inFuture',
  }

  const generateChildren = (children) => {
    return children.map((child) => {
      return {
        status: conversationKeyStatus[child.status],
        title: child.conversationTitle,
        conversationKey: child.conversationKey,
        children:
          child.children.length > 0 ? generateChildren(child.children) : [],
      }
    })
  }

  const nextConversation = async () => {
    const conversationMessages = await moveToNextConversation({
      conversationKey,
    })

    if (conversationMessages.data.data) {
      const conversationsOutlines = await getConversationsOutlines()

      dispatch(
        setConversationKey(conversationMessages.data.data.conversationKey)
      )

      const outlineOject = {
        type:
          conversationsOutlines.data.data.length > 0
            ? 'MultiSeparator'
            : 'singleSeparator',
        title: conversationMessages.data.data.conversationTitle,
        conversationKey: conversationMessages.data.data.conversationKey,
        list: generateChildren(conversationsOutlines.data.data),
      }

      const outlineMessagesArray = () => {
        return conversationMessages.data.data.chatMessages.map((message) => {
          const messageObject = {
            type: 'message',
            conversationKey: conversationMessages.data.data.conversationKey,
            owner: 'OSOS-AI',
            id: message.id,
            status: 'response',
            message: message.content.replace(/(\r\n|\n|\r)/g, "<br>"),
            tag: message.tag,
            time: message.createTime,
          }
          return actionsCheck(message, messageObject)
        })
      }
      dispatch(setTextLoading(false))

      dispatch(addMessage([outlineOject, ...outlineMessagesArray()]))
    } else {
      // if executeOutputResult has value not 401
      const ifResult = (result) => {
        if (!result?.data?.data) return

        const newMessages = result.data.data.map((message) => {
          const messageObject = {
            type: 'message',
            conversationKey,
            owner: 'OSOS-AI',
            id: message.id,
            status: 'response',
            message: message.content.replace(/(\r\n|\n|\r)/g, "<br>"),
            tag: message.tag,
            time: message.createTime,
          }

          return actionsCheck(message, messageObject)
        })
        dispatch(setTextLoading(false))
        dispatch(addMessage(newMessages))
      }
      // if executeOutputResult   value  undefined because it is 401
      const checkResultInterval = async () => {
        if (localStorage.getItem('token')) {
          requestNumberCall += 1
          if (requestNumberCall > 3) return
          const result = await executeOutputResult()
          if (result) {
            ifResult(result)
          } else {
            checkResultInterval()
          }
        } else {
          setTimeout(() => {
            checkResultInterval()
          }, 500)
        }
      }

      const result = await executeOutputResult()
      if (!result) {
        checkResultInterval()
      } else {
        requestNumberCall = 0
        ifResult(result)
      }
    }
  }

  const messageResponse = (messageArray) => {
    if (!Array.isArray(messageArray?.data)) return

    let callNext = false
    const newMessages = messageArray?.data?.map((message) => {
      if ([2, 4].includes(message.tag)) {
        callNext = true
      }

      const messageObject = {
        type: 'message',
        conversationKey,
        owner: 'OSOS-AI',
        id: message.id,
        status: 'response',
        message: message.content.replace(/(\r\n|\n|\r)/g, "<br>"),
        tag: message.tag,
        time: message.createTime,
      }

      return actionsCheck(message, messageObject)
    })

    dispatch(addMessage(newMessages))

    if (callNext) {
      nextConversation()
      dispatch(setTextLoading(true))
    }
  }

  return { messageResponse, actionsCheck }
}
