import { createSlice } from '@reduxjs/toolkit'

export const mainSlice = createSlice({
  name: 'main',
  initialState: {
    currentMode: localStorage.getItem('CurrentMode') || 'light',
    interface: localStorage.getItem('interface')
      ? localStorage.getItem('interface')
      : 'Comfy',
    direction: localStorage.getItem('NEXT_LOCALE') || 'en',
    preloader: true,
    borderRound: true,
    drawerAi: {
      status: false,
      currentComponent: 'chatBot',
    },
    zoomSlider: 50,
    newToken: '',
  },
  reducers: {
    directionFun: (state, action) => {
      state.direction = action.payload
    },
    changeMode: (state, action) => {
      state.currentMode = action.payload
      localStorage.setItem('CurrentMode', action.payload)
    },
    changePreloader: (state, action) => {
      state.preloader = action.payload
    },
    // changeBorderRound: (state, action) => {
    //   state.borderRound = action.payload
    // },

    // ChangeZoomSlider: (state, action) => {
    //   state.zoomSlider = action.payload
    // },
    // ChangeSideBar: (state, action) => {
    //   state.sidebar = action.payload
    // },

    setNewToken: (state, action) => {
      state.newToken = action.payload
      localStorage.setItem('token', action.payload)
    },
  },
})

export const {
  directionFun,
  changePreloader,
  changeMode,
  addToHistory,
  changeCurrentComponent,
  ChangeDrawerStatus,
  ChangeZoomSlider,
  ChangeSideBar,
  ChangeInterface,
  changeHelpStatus,
  ChangeDrawerAiStatus,
  setNewToken,
} = mainSlice.actions
export default mainSlice.reducer
