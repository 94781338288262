import React, { useEffect } from 'react'
import GlobalStyle from './styledComponent/globalStyles'
import { useSelector, useDispatch } from 'react-redux'
import Override from './styledComponent/override'
import { directionFun, setNewToken } from '../../store/slices/main'
import { IntlProvider } from 'react-intl'
import arFile from '../../lang/ar.json'
import enFile from '../../lang/en.json'
export default function AppWrapper({ children }) {
  const isEnglish = window.location.href.endsWith('/ar') ? false : true
  const dispatch = useDispatch()
  const currentMode = useSelector((state) => state.main.currentMode)
  const direction = useSelector((state) => state.main.direction)
  const borderRound = useSelector((state) => state.main.borderRound)
  const messages = isEnglish ? enFile : arFile
  dispatch(directionFun(window.location.href.endsWith('/ar') ? 'ar' : 'en'))

  useEffect(() => {
    window.addEventListener('message', (event) => {
      // Check the origin to ensure it's trusted
      if (document.referrer.includes(event.origin)) {
        console.log('Received message in iframe:', event.data)
        dispatch(setNewToken(event.data.token))
        localStorage.setItem('userId', event.data.userId)
      }
    })
    {
      document.referrer && window.parent.postMessage('token', '*')
    }
  }, [])

  return (
    <>
      <GlobalStyle
        currentMode={currentMode}
        direction={direction}
        borderRound={borderRound}
      />
      <Override direction={direction} currentMode={currentMode} />
      <div className="appWrapper" dir={direction === 'ar' ? 'rtl' : 'ltr'}>
        <IntlProvider
          locale={direction === 'rtl' ? 'ar' : 'en'}
          messages={messages}
        >
          {children}
        </IntlProvider>
      </div>
    </>
  )
}
