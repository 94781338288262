/* eslint-disable no-unused-expressions */
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { changePreloader } from '../store/slices/main'
import { addMessage, setTextLoading } from '../store/slices/chat'

const useApi = () => {
  const baseURL = localStorage.getItem('baseUrl')
  let axiosObject = {
    baseURL,
  }
  const isEnglish = window.location.href.endsWith('/ar') ? false : true
  const mainInstance = axios.create(axiosObject)
  const dispatch = useDispatch()
  mainInstance.interceptors.request.use(
    function (config) {
      const endpoint = localStorage.getItem('endpoint')
        ? localStorage.getItem('endpoint')
        : 'v1/chatBroker'
      config.baseURL = config.baseURL + endpoint
      dispatch(changePreloader(true))
      const noAuthRoutes = []
      //* add auth
      if (!noAuthRoutes.includes(config.url)) {
        config.headers.OsosAITokenKey = localStorage.getItem('token')
        config.headers.OsosAIUserId =
          localStorage.getItem('userId') ||
          'drhc88blTmLFyS1ZDfqrzubut6TEye_HoCwEqiY8Uo8'
        config.headers.OsosAILanguageCode = isEnglish ? 'en' : 'ar'
      }
      //* end auth
      return config
    },
    (error) => {
      console.log(error)
      return
    }
  )

  mainInstance.interceptors.response.use(
    async (res) => {
      dispatch(changePreloader(false))
      return res
    },
    async (err) => {
      console.log({ err })
      dispatch(changePreloader(false))
      if (err?.response?.status == 401) {
        {
          document.referrer &&
            window.parent.postMessage('token', document.referrer)
        }
        localStorage.removeItem('token')
      }

      const errorMessagesArray = []
      err.response.data.messages.map((message) => {
        errorMessagesArray.push({
          type: 'message',
          id: '2',
          owner: 'OSOS-AI',
          status: 'error',
          message: message.message,
          time: new Date(),
        })
      })
      dispatch(addMessage(errorMessagesArray))
      dispatch(setTextLoading(false))
      return
    }
  )

  return mainInstance
}

export default useApi
