import styled from 'styled-components'
export const Wrapper = styled.div`
  width: 70%;
  .chat {
    display: flex;
    overflow: hidden;
  }
  .chat-content {
    width: calc(100% - 60px);
  }

  .chat-avatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin: 0;
    margin-right: 10px;
    padding: 0;
    overflow: hidden;
  }

  .chat-text {
    &--small {
      width: 50%;
      height: 40px;
      border-radius: 5px;
    }

    &--large {
      width: 100%;
      height: 74px;
      margin-top: 5px;
      border-radius: 5px;
    }
  }

  .loading {
    position: relative;
    background-color: #e2e2e2;

    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.6),
        transparent
      );
      animation: loading 1.5s infinite;
    }
  }

  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }
`
