import { createSlice, current } from '@reduxjs/toolkit'

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    currentMessage: '',
    allMessage: [],
    conversationKey: '',
    reload: 0,
    textLoading: false,
  },
  reducers: {
    setMessage: (state, action) => {
      state.currentMessage = action.payload
    },
    setConversationKey: (state, action) => {
      state.conversationKey = action.payload
    },
    setAllMessage: (state, action) => {
      state.allMessage = action.payload
    },
    addMessage: (state, action) => {
      state.allMessage = [...current(state.allMessage), ...action.payload]
    },

    reloadFun: (state, action) => {
      state.reload = state.reload + 1
    },
    setTextLoading: (state, action) => {
      state.textLoading = action.payload
    },
  },
})

export const {
  setMessage,
  setAllMessage,
  addMessage,
  setConversationKey,
  reloadFun,
  setTextLoading,
} = chatSlice.actions
export default chatSlice.reducer
