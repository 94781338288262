import React from 'react'
 import { Wrapper } from './Main.styled';
import NoContent from './NoContent/NoContent';
import ChatContainer from './ChatContainer/ChatContainer';
export default function Main() {
    const messages = [ "Hello" ]
  return (
    <Wrapper className="flex flex-col  flex-grow ">
          {messages.length ? <ChatContainer /> : <NoContent />}
 </Wrapper>
  )
}
