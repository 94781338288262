import React from 'react'
import { Wrapper } from './ChatSkeleton.styled'

export default function ChatSkeleton() {
  return (
    <div>
      <Wrapper className="mt-4">
        <div className="chat">
          <figure className="chat-avatar loading"></figure>
          <div className="chat-content  rtl:mr-3">
            <div className="chat-text--small loading"></div>
            <div className="chat-text--large loading"></div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="mt-4  ml-auto rtl:ml-0 rtl:mr-auto">
        <div className="chat flex-row-reverse">
          <figure className="chat-avatar loading"></figure>
          <div className="chat-content flex flex-col items-end mr-3 rtl:mr-0 rtl:ml-3">
            <div className="chat-text--small loading"></div>
            <div className="chat-text--large loading"></div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="mt-4">
        <div className="chat">
          <figure className="chat-avatar loading"></figure>
          <div className="chat-content rtl:mr-3">
            <div className="chat-text--small loading"></div>
            <div className="chat-text--large loading"></div>
          </div>
        </div>
      </Wrapper>
      <Wrapper className="mt-4  ml-auto rtl:ml-0  rtl:mr-auto">
        <div className="chat flex-row-reverse">
          <figure className="chat-avatar loading"></figure>
          <div className="chat-content flex flex-col items-end mr-3 rtl:mr-0 rtl:ml-3">
            <div className="chat-text--small loading"></div>
            <div className="chat-text--large loading"></div>
          </div>
        </div>
      </Wrapper>
    </div>
  )
}
