import styled from 'styled-components'
export const Wrapper = styled.div`
  position: relative;
  z-index: 100;
  width: 20rem;

  [class*='-control'] {
    border: 0;
    outline: 0;
    box-shadow: unset;
    border-radius: 0.3rem;
  }

  [class*='-menu'] {
    width: 100%;
    position: absolute !important;
    border: 0;
    outline: 0;
    bottom: 100%;
    top: unset;
    [class*='-menu'] > div {
      div {
        background: var(--primary-100);
        border-bottom: 1px solid var(--primary-200);
        &:hover {
          background-color: var(--primary-600);
          color: var(--themeWhite-white);
        }
      }
    }
  }
`
