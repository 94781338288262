import React, { useEffect, useRef, useState } from 'react'
import { Wrapper } from '../Main.styled'
import Message from './Message/Message'
import FeedBack from './FeedBack/FeedBack'
import SingleSeparator from './SingleSeparator/SingleSeparator'
import MultiSeparator from './MultiSeparator/MultiSeparator'
import SelectUser from './SelectUser/SelectUser'
import useRequest from '../../../../axios/apis/useRequest'
import ChatSkeleton from './ChatSkeleton/ChatSkeleton'
import { useDispatch, useSelector } from 'react-redux'
import useChat from '../../../../hooks/useChat'
import {
  addMessage,
  setAllMessage,
  setConversationKey,
} from '../../../../store/slices/chat'
import { findLastOwnerIndex } from '../../../../functions/common/common'

export default function ChatContainer({ className }) {
  const { actionsCheck } = useChat()
  const { loadConversation, getConversationsOutlines } = useRequest()
  const allMessage = useSelector((state) => state.chat.allMessage)
  const reload = useSelector((state) => state.chat.reload)
  const textLoading = useSelector((state) => state.chat.textLoading)
  const chatContainer = useRef()
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [type, setType] = useState()

  const [messageId, setMessageId] = useState()
  const [messageConversationKey, setMessageConversationKey] = useState()

  const conversationKeyStatus = {
    0: 'inFuture',
    1: 'done',
    2: 'inprogress',
    3: 'inFuture',
  }

  const generateChildren = (children, conversationKey) => {
    if (children.length === 0) {
      dispatch(setConversationKey(conversationKey))
    }

    return children.map((child) => {
      return {
        status: conversationKeyStatus[child.status],
        title: child.conversationTitle,
        conversationKey: child.conversationKey,
        children:
          child.children.length > 0 ? generateChildren(child.children) : [],
      }
    })
  }

  // this function to make array objects children in the same array for display outline with index
  function flattenOutlineChildren(data) {
    let result = []

    data?.forEach((item) => {
      result.push(item)

      if (item.children.length > 0) {
        result = result.concat(flattenOutlineChildren(item.children))
      }
    })

    return result
  }

  useEffect(() => {
    ;(async () => {
      const chatMessageApi = await loadConversation()
      const outlineApi = await getConversationsOutlines()
      let messagesArray = []
      const flattenOutline = flattenOutlineChildren(outlineApi?.data?.data)
      chatMessageApi?.data?.data.map((conversation, index) => {
        const conversationKey = flattenOutline[index]?.conversationKey
        const conversationTitle = flattenOutline[index]?.conversationTitle
        if (flattenOutline[index]) {
          dispatch(setConversationKey(conversationKey))
          const outlineOject = {
            type:
              outlineApi.data.data.length > 1
                ? 'MultiSeparator'
                : 'singleSeparator',
            conversationKey: conversationKey,
            title: conversationTitle,
            list: generateChildren(outlineApi.data.data, conversationKey),
          }
          messagesArray = [...messagesArray, outlineOject]
        }

        conversation.chatMessages.map((message) => {
          const messageObject = {
            conversationKey,
            owner: message.role == 0 || message.role == 1 ? 'you' : 'OSOS-AI',
            type: 'message',
            status: 'response',
            id: message.id,
            tag: message.tag,
            message: message.content.replace(/(\r\n|\n|\r)/g, "<br>"),
            time: message.createTime,
          }
          messagesArray = [
            ...messagesArray,
            actionsCheck(message, messageObject),
          ]
        })
      })

      dispatch(addMessage(messagesArray))
    })()
  }, [reload])

  useEffect(() => {
    const scrollableDiv = chatContainer.current
    scrollableDiv.scrollTop = scrollableDiv.scrollHeight
  }, [allMessage.length])

  return (
    <Wrapper className={`magic ${className}`}>
      <div
        ref={chatContainer}
        className="messagesContainer p-1 pt-0 overflow-auto"
        style={{ height: 'calc(100vh - 10rem)' }}
      >
        {allMessage.map((message, index) => (
          // hide sticky
          <span
            key={index}
            className={` ${
              message.type === 'singleSeparator' ||
              message.type === 'MultiSeparator'
                ? ' mb-0 z-20 bg-themeWhite-white  top-[-0rem] min-h-[4rem] flex flex-col justify-center'
                : 'mb-4 block'
            }`}
          >
            {message.type === 'message' ? (
              <Message
                messageData={message}
                setMessageId={setMessageId}
                setMessageConversationKey={setMessageConversationKey}
                setType={setType}
                setShow={setShow}
                index={index}
                tryAgain={
                  findLastOwnerIndex(allMessage) === index &&
                  allMessage[allMessage.length - 1]?.status === 'error'
                }
                reGenerate={
                  allMessage.length - 1 === index &&
                  message.owner === 'OSOS-AI' &&
                  message.status === 'response' &&
                  message.tag != '100'
                }
                edit={
                  findLastOwnerIndex(allMessage) === index &&
                  message.tag != '100'
                }
              />
            ) : message.type === 'singleSeparator' ? (
              <SingleSeparator title={message.title} conversationKey={message.conversationKey} />
            ) : (
              <MultiSeparator message={message} />
            )}
          </span>
        ))}

        {allMessage[allMessage.length - 1]?.tag === 1 ? <SelectUser /> : null}
        {allMessage[allMessage.length - 1]?.owner === 'you' ||
        textLoading == true ? (
          <Message
            messageData={{
              type: 'message',
              id: '6',
              owner: 'OSOS-AI',
              status: 'loading',
              message: '',
              time: new Date(),
            }}
          />
        ) : null}
        {allMessage.length == 0 && <ChatSkeleton />}
      </div>
      {show && (
        <FeedBack
          type={type}
          show={show}
          setShow={setShow}
          messageId={messageId}
          messageConversationKey={messageConversationKey}
        />
      )}
    </Wrapper>
  )
}
