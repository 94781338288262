import React, { useState } from 'react'
import { HiChevronDown } from 'react-icons/hi'
import SingleSeparator from '../SingleSeparator/SingleSeparator'
import { FaRegCircle, FaRegCheckCircle } from 'react-icons/fa'
import { FormattedMessage } from 'react-intl'

export default function MultiSeparator({ message }) {
  const [status, setStatus] = useState(false)
  const types = {
    done: {
      icon: <FaRegCheckCircle />,
      color: 'text-themeGreen-600',
    },
    inprogress: {
      icon: (
        <span className='relative before:content-["▶"] before:left-[-0.8rem] before:top-[50%] rtl:scale-x-[-1] block before:translate-y-[-50%] before:text-[0.7rem] before:absolute'>
          <FaRegCircle />
        </span>
      ),
      color: 'text-primary-600',
    },
    inFuture: {
      icon: (
        <span className="text-themeGray-400">
          <FaRegCircle />
        </span>
      ),
      color: 'text-themeGray-600',
    },
  }

  const body = (items) => (
    <div className="ml-5 rtl:mr-5 rtl:ml-0 text-sm">
      {items.map((item, index) => (
        <div>
          <div
            key={index}
            className={`${types[item.status]?.color} flex items-center py-1`}
          >
            <span className="icon mr-2 rtl:ml-2 rtl:mr-0 text-lg">
              {types[item.status]?.icon}
            </span>
            <span className="font-semibold">{item.title}</span>
          </div>
          {item.children?.length > 0 && body(item.children)}
        </div>
      ))}
    </div>
  )

  return (
    <div
      className={`border-primary-200 border rounded-md p-2  bg-primary-50  my-5`}
    >
      <div className={`accordionHead flex items-center justify-between py-1`}>
        <button
          onClick={() => setStatus(!status)}
          className={`chev  text-xl text-themeGray-500 pr-2 rtl:pl-2 rtl:pr-0`}
        >
          <span
            className={`block ease-in-out duration-300 ${
              status ? 'rotate-180' : 'rotate-0'
            }`}
          >
            <HiChevronDown />
          </span>
        </button>
        <div className="w-full">
          <SingleSeparator
            title={
              <>
                <span className="font-normal mr-1 rtl:mr-0 rtl:ml-1">
                  <FormattedMessage id="Step:" />
                </span>
                <span className=" text-ellipsis overflow-hidden whitespace-nowrap inline-block max-w-[9rem]">
                  {' '}
                  {message.title}
                </span>
              </>
            }
            conversationKey={message.conversationKey}
            lineColor="bg-primary-200"
          />
        </div>
      </div>
      <div
        className={`accordionBody overflow-hidden transition-max-h-screen ease-in-out duration-500 ${
          status ? 'max-h-screen' : 'max-h-0'
        } ${status}`}
      >
        <div className="mainBody ml-2">{body(message.list)}</div>
      </div>
    </div>
  )
}
