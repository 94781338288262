import MagicButton from '../../../MagicButton/MagicButton'
import { formatTimeDifference } from '../../../../../functions/time/time'
import { AiOutlineDislike, AiOutlineLike } from 'react-icons/ai'
import TextLoading from '../TextLoading/TextLoading'
import { PiArrowsClockwiseFill } from 'react-icons/pi'
import { GrPowerReset } from 'react-icons/gr'
import { HiOutlinePencil } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import { setAllMessage } from '../../../../../store/slices/chat'
import useRequest from '../../../../../axios/apis/useRequest'
import { useState } from 'react'
import { findLastOwnerIndex } from '../../../../../functions/common/common'
import useChat from '../../../../../hooks/useChat'
import { IoCheckmarkSharp } from 'react-icons/io5'
import { FormattedMessage, useIntl } from 'react-intl'

export default function Message({
  messageData,
  setMessageId,
  setMessageConversationKey,
  setShow,
  setType,
  reGenerate,
  edit,
  tryAgain,
  index,
}) {
  const [editValue, setEditValue] = useState(messageData.message)
  const [isEdit, setIsEdit] = useState(false)
  const preloader = useSelector((state) => state.main.preloader)

  const dispatch = useDispatch()
  const allMessage = useSelector((state) => state.chat.allMessage)
  const conversationKey = useSelector((state) => state.chat.conversationKey)

  const { regenerateAnswer, rewriteUserMessage, postMessage } = useRequest()
  const regenerate = async () => {
    const regenerate = await regenerateAnswer({ conversationKey })

    const newMessages = regenerate.data.data.map((message) => {
      return {
        type: 'message',
        conversationKey,
        owner: 'OSOS-AI',
        id: message.id,
        status: 'response',
        message: message.content.replace(/(\r\n|\n|\r)/g, "<br>"),
        tag: message.tag,
        time: message.createTime,
      }
    })

    const deleteUntilIndex = findLastOwnerIndex(allMessage) + 1
    const newArray = allMessage.slice(0, deleteUntilIndex)

    dispatch(setAllMessage([...newArray, ...newMessages]))
  }
  const { messageResponse } = useChat()

  const sendEdit = async (e) => {
    e.preventDefault()
    if (editValue.length > 0) {
      setIsEdit(false)
      await rewriteUserMessage({ conversationKey })

      const deleteUntilIndex = findLastOwnerIndex(allMessage)
      const newArray = allMessage.slice(0, deleteUntilIndex)
      const newMessage = {
        type: 'message',
        conversationKey,
        owner: 'you',
        status: 'response',
        message: editValue,
        tag: 0,
        time: new Date(),
      }
      dispatch(setAllMessage([...newArray, newMessage]))

      const sendMessage = await postMessage({
        conversationKey,
        message: editValue,
      })
      messageResponse(sendMessage?.data)
    }
  }

  const tryAgainAction = async () => {
    //await rewriteUserMessage({ conversationKey })

    const deleteUntilIndex = findLastOwnerIndex(allMessage)
    const newArray = allMessage.slice(0, deleteUntilIndex)

    const newMessage = {
      type: 'message',
      conversationKey,
      owner: 'you',
      status: 'response',
      message: messageData.message,
      tag: 0,
      time: new Date(),
    }

    dispatch(setAllMessage([...newArray, newMessage]))

    const sendMessage = await postMessage({
      conversationKey,
      message: messageData.message,
    })
    messageResponse(sendMessage?.data)
  }

  const messageActions = () => {
    let actions = ''
    if (messageData.messageActions && messageData.messageActions.length > 0) {
      messageData.messageActions.map((action) => {
        actions += ` <a target="_blank"
          href=${action.url}
          class=" underline text-primary-600 mx-1"
          rel="noreferrer"
        > ${action.urlTitle} </a>`
      })
    }

    return actions
  }

  return (
    <div
      className={`message flex items-start mt ${
        messageData?.owner !== 'you'
          ? 'mr-auto rtl:mr-0 rtl:ml-auto max-w-[90%]  mb-5'
          : 'ml-auto rtl:ml-0 rtl:mr-auto max-w-[70%] justify-end '
      } 
      `}
    >
      {messageData?.owner !== 'you' && (
        <MagicButton
          bg="var(--primary-25)"
          className={`mr-3 rtl:mr-0 rtl:ml-3 md:p-3 p-2 min-w-[2.5rem] ${
            allMessage[index - 1]?.owner == 'OSOS-AI' && 'invisible'
          }`}
        />
      )}

      <div className={messageData.status != 'loading' ? 'min-w-[10rem]' : ''}>
        {!(
          allMessage[index - 1]?.owner == 'OSOS-AI' &&
          messageData?.owner == 'OSOS-AI'
        ) && (
          <div className="flex justify-between">
            <div className="name text-sm text-themeGray-700 font-semibold">
              <FormattedMessage id={messageData.owner} />
            </div>
            {messageData.status != 'loading' && (
              <div className="time text-themeGray-500 text-xs flex items-center ml-2 rtl:mr-2 rtl:ml-0">
                {formatTimeDifference(messageData.time)}
              </div>
            )}
          </div>
        )}
        <form>
          <div
            className={`${
              messageData.owner !== 'you'
                ? '  rounded-tl-[0px] rtl:rounded-tr-[0px] rtl:rounded-tl-xl '
                : 'rounded-tr-[0px]  rtl:rounded-tl-[0px]  rtl:rounded-tr-xl '
            } message relative px-3 py-2 rounded-xl mt-2 text-base ${
              messageData.status == 'error'
                ? 'bg-error-100 text-error-700'
                : messageData.owner == 'you'
                  ? 'bg-primary-600 text-themeWhite-white'
                  : 'bg-themeGray-100 text-themeGray-900'
            }`}
          >
            {messageData.status != 'loading' ? (
              <>
                {isEdit ? (
                  <>
                    <input
                      type="text"
                      value={editValue}
                      className=" max-w-full text-themeGray-700 border border-themeWhite-white px-2 rounded-md outline-none pr-[5rem] rtl:pr-2 rtl:pl-[5rem]"
                      onChange={(e) => setEditValue(e.target.value)}
                    />

                    <button
                      disabled={preloader}
                      type="submit"
                      onClick={sendEdit}
                      className="shadow-[0_0px_0.1rem_var(--primary-500)] flex items-center justify-center px-2 text-sm bg-primary-50 text-primary-600  absolute rtl:left-[0.95rem] rtl:right-auto top-[0.7rem] right-[0.95rem] rounded-md"
                    >
                      <span className="px-1">
                        <FormattedMessage id="Send" />
                      </span>
                      <IoCheckmarkSharp />
                    </button>
                  </>
                ) : (
                  <div className="messageContainer flex items-center justify-between">
                    {messageData.messageActions ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: messageData.message + messageActions(),
                        }}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: messageData.message,
                        }}
                      />
                    )}
                  </div>
                )}
              </>
            ) : (
              <TextLoading />
            )}
            {messageData.owner !== 'you' &&
              messageData.status == 'response' && (
                <div className="react text-themeGray-400 text-base absolute left-0 rtl:left-auto rtl:right-0  bottom-[-1.6rem] ">
                  <button
                    className="like p-0.5"
                    type="button"
                    onClick={() => {
                      setMessageId(messageData.id)
                      setMessageConversationKey(messageData.conversationKey)
                      setShow(true)
                      setType('like')
                    }}
                  >
                    <AiOutlineLike />
                  </button>
                  <button
                    className="dislike p-0.5"
                    type="button"
                    onClick={() => {
                      setMessageId(messageData.id)
                      setMessageConversationKey(messageData.conversationKey)
                      setShow(true)
                      setType('dislike')
                    }}
                  >
                    <AiOutlineDislike />
                  </button>
                </div>
              )}
          </div>

          <div className="flex">
            {reGenerate && (
              <button
                className="flex items-center  text-xs text-themeGreen-700 justify-start ml-auto rtl:ml-0 rtl:mr-auto"
                type="button"
                onClick={regenerate}
                disabled={preloader}
              >
                <PiArrowsClockwiseFill />
                <span className="m-1">
                  <FormattedMessage id="Regenerate" />
                </span>
              </button>
            )}
            {tryAgain && (
              <button
                className="flex items-center  text-xs text-error-700"
                type="button"
                onClick={(e) => {
                  tryAgainAction(e)
                }}
              >
                <GrPowerReset />
                <span className="m-1">
                  <FormattedMessage id="Try-again" />
                </span>
              </button>
            )}
            {edit && !tryAgain && (
              <button
                className="flex items-center  text-xs text-theGray-700 ml-auto rtl:ml-0 rtl:mr-auto"
                type="button"
                onClick={() => {
                  setIsEdit(!isEdit)
                }}
              >
                <HiOutlinePencil />
                <span className="m-1">
                  <FormattedMessage id="Edit-my-message" />
                </span>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
