import React from 'react'
import { Wrapper } from './MagicButton.style'
import star from '../../../assets/images/star.svg';

export default function MagicButton({className, bg ='var(--themeWhite-white)' }) {
  return (
    <Wrapper className={`magic rounded-full ${className}`} bg={bg}>
      <img src={star} alt="" className="" />
    </Wrapper>
  )
}
